import React, { useEffect, useState } from 'react';

import BigCalendar from "react-big-calendar";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import apiCall from '../Actions/apiCall';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

function EventCalendars() {
  const [showModal, setShowModal] = useState(false);
  // let allViews = Object.keys(BigCalendar.Views).map((k) => BigCalendar.Views[k]);
  const staticImage = "https://t4.ftcdn.net/jpg/02/39/97/31/360_F_239973168_THApLcIPF6hu7ElDjH74MA5M9qr7MePg.jpg"
  const [apiCalendarData, setApiCalendarData] = useState([]);
  // const [apiAssignData, setApiAssignData] = useState([]);
  const [loading, setLoading] = useState(false);

  // const events = [
  //     {
  //         id: 0,
  //         guideName: "Dev test",
  //         image: "http://3.110.29.31:8090/uploads/1688391098394-image_picker_751f9a1e-c45f-4141-9926-1f4bb74146d8-984-00000054973d5db1.jpg",
  //         allDay: true,
  //         language: "English",
  //         time_from: "14:00",
  //         time_to: "17:00",
  //         start: new Date(`2023-07-09T05:48:08.218Z`),
  //         end: new Date(`2023-07-09T05:48:08.218Z`),
  //         title: 'Dev test',
  //         type: "Arena"
  //     },
  //     {
  //         id: 1,
  //         guideName: "Festival",
  //         image: "https://t4.ftcdn.net/jpg/02/39/97/31/360_F_239973168_THApLcIPF6hu7ElDjH74MA5M9qr7MePg.jpg",
  //         allDay: true,
  //         language: "Bangali",
  //         time_from: "12:00",
  //         time_to: "11:00",
  //         start: new Date(`2023-07-15T05:48:08.218Z`),
  //         end: new Date(`2023-07-15T05:48:08.218Z`),
  //         title: 'Festival',
  //         type: "Frontend"
  //     },
  //     {
  //         id: 2,
  //         guideName: "Diwali",
  //         image: "https://t4.ftcdn.net/jpg/02/39/97/31/360_F_239973168_THApLcIPF6hu7ElDjH74MA5M9qr7MePg.jpg",
  //         allDay: true,
  //         language: "",
  //         time_from: "12:00",
  //         time_to: "11:00",
  //         start: new Date(`2023-07-15T05:48:08.218Z`),
  //         end: new Date(`2023-07-16T05:48:08.218Z`),
  //         title: 'Diwali',
  //         type: "Ordinario"
  //     },
  //     {
  //         id: 2,
  //         guideName: "Dushera",
  //         image: "https://t4.ftcdn.net/jpg/02/39/97/31/360_F_239973168_THApLcIPF6hu7ElDjH74MA5M9qr7MePg.jpg",
  //         allDay: true,
  //         language: "",
  //         time_from: "01:00",
  //         time_to: "05:00",
  //         start: new Date(`2023-07-12T05:48:08.218Z`),
  //         end: new Date(`2023-07-12T05:48:08.218Z`),
  //         title: 'Meeting2',
  //         type: "UG"
  //     },
  // ];

  // let GetWebSchedule = async () => {
  //   let apiRes = await apiCall.GetScheduleWeb();
  //   if (apiRes?.status >= 200 || apiRes?.status <= 399) {
  //     console.log("apiRes1123----->", apiRes);
  //     // setApiCalendarData(apiRes?.data);
  //   };
  // };
  let getGuides = async () => {
    let apiRes = await apiCall.GetGuideWeb();
    if (apiRes?.status) {
      console.log("apiRes11232----->", apiRes?.data);
      setApiCalendarData(apiRes?.data);
    };
  };
  const [currentEvent, setCurrentEevent] = useState(null);
  const openModal = (event) => {
    if (event?.guides.length == 0) {
      toast.error("No guides available");
      return;
    }
    setCurrentEevent(event)
    setTourId(event?.id);
    console.log(":event?.id", event?.id);
    console.log("setCurrentEevent", event);
    setShowModal(true);
  }
  const [tourId, setTourId] = useState(null);
  const [guideId, setGuideId] = useState(null);

  const handleSubmit = async () => {
    if (!guideId || !tourId) {
      toast.error("All fields are required.")
      return;
    }
    setLoading(true);
    let payload = {
      guide_id: guideId,
      tour_id: tourId
    }
    let apiRes = await apiCall.assignGuide(payload);
    setLoading(false);
    if (apiRes.status) {
      toast.success(apiRes?.message)
      setShowModal(false);
      getGuides();
      // console.log("apiRes......", apiRes);
    }
  }
  const changeGuide = (e) => {
    setGuideId(e.target.value)
  }
 


  const [selectedDate, setSelectedDate] = useState(new Date());


  

  useEffect(() => {
    // GetWebSchedule();
    getGuides();
  }, [])

  const eventComponent = ({ event }) => {
    return (
      <>
        {/* <div className="orange-sec">
                    <div className="orange-inner">
                        <div className="first-green-col">
                            <div className="img-clm"><img src="images/luka.png" alt=""/></div>
                            <div className="name-clm">Luca </div>
                        </div>
                        <div className="calendar-buttons">
                            <div className="green-btn-frst"><span>Language : English</span></div>
                            <div className="green-btn-scnd"><span>Time Zone : PM</span></div>
                        </div>
                    </div>
                </div> */}

        <div>
          <div className={`${event.type === `Arena` ? `orange-sec` : event.type === `Regular` ? `blue-sec` : event.type === `UGOR` ? `green-sec` : event.type === `UGVI` ? `yellow-sec` : ``}`} >
            <div className={`${event.type === `Arena` ? `orange-inner` : event.type === `Regular` ? `skyblue-inner` : event.type === `UGOR` ? `green-inner` : event.type === `UGVI` ? `yellow-inner` : ``}`}>
              <div className="first-green-col">
                <div className="img-clm"><img src={!event.image ? staticImage : event.image} alt="" /></div>
                <div className="name-clm">{event.guide_name} <div className="assign-guide" onClick={() => openModal(event)}>
                  Assign Guide <i class="fa fa-plus"></i>
                </div> </div>
              </div>

              <div className="calendar-buttons">
                <div className="green-btn-frst"><span>Lang : {event.language}</span></div>
                {/* <div className="green-btn-scnd"><span>{event.time_from} - {event.time_to}</span></div> */}
              </div>
            </div>
          </div>
          {/* <div className="sap-tour-inner">
                        <div className="sti-tourer-left">
                            <div className="sti-tourer-img"><img src={!event.image ? staticImage : event.image} alt="" /></div>
                        </div>
                        <div className="sti-tourer-right">
                            <div className="sti-tourer-name">{event.guideName}</div>
                            <div className="sti-tourer-lang">
                                <span className="sti-tourer-heading">Language -</span>
                                <span className="sti-tourer-detail">{event.language}</span>
                            </div>
                            <div className="sti-tourer-time">
                                <span className="sti-tourer-heading">Time -</span>
                                <span className="sti-tourer-detail">{event.time_from} - {event.time_to}</span>
                            </div>
                        </div>
                    </div> */}
        </div>
        {/* {/ Change Role Modal /} */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Guide Listing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Guide <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                </label>
                <select
                  className="form-select no-space"
                  aria-label="Default Select Guide"
                  onChange={(e) => changeGuide(e)}
                // defaultChecked="Select Role"
                >
                  <option selected={guideId == null} disabled>Select Guide</option>
                  {currentEvent?.guides.map((guide, index) => (
                    <option value={guide?._id} selected={guide?._id == guideId}>
                      {guide?.full_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={() => handleSubmit()} disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  // console.log('currentSelectedDate',currentSelectedDate)

  return (
    <div className="" style={{ minHeight: 580 }}>
      <div>
        <h1>Select Date</h1>
        <DatePicker selected={selectedDate}  onChange={(date) => setSelectedDate(date)} />
        {selectedDate && (
          <BigCalendar
          events={apiCalendarData}
          views={['week' , 'day']}
          step={60}
          showMultiDayTimes
          date={selectedDate} // Use selectedDate to control the displayed date
          defaultView="week"
          style={{ minHeight: 580 }}
          components={{
            event: eventComponent
          }}
          onSelectSlot={slotInfo => setSelectedDate(slotInfo.start)}
          onNavigate={newDate => setSelectedDate(newDate)} // Update selectedDate when navigating
        />
        )}
      </div>
    </div>
  );
}

export default EventCalendars;
import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import moment from "moment";
import Calendar from 'react-calendar';
import { toast } from 'react-toastify';
import { FaRegCalendarAlt } from "react-icons/fa";

import apiCall from './Actions/apiCall';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Home = () => {
    const guide_listing = useRef();
    const navigate = useNavigate();

    // time show structure array
    const timeStart = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
    const timeEnd = ["00", "30"];
    const time_status = ["AM", "PM", "Full_Day"];


    const [value, onChange] = useState(new Date());
    const [guide_details, setGuide_details] = useState();
    const [list_Tour, setlist_Tour] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [tourListScroll, setTourListScroll] = useState("");


    const [tourDetails, setTourDetails] = useState({
        name: "",
        type: "Arena",
        language: "German",
        startTimeHour: "01",
        startTimeMinutes: "00",
        endTimeHour: "01",
        endTimeMinutes: "00",
        time_status: "AM",
        date: new Date()
    });

    const [submitData, setSubmitData] = useState({
        present_date: "",
        guide_listing_id: "",
        listing_tour_id: "",
    });

    const [isNameValid, setIsNameValid] = useState(false)
    const [tour_id, setTour_id] = useState("");


    let GuideAvailability = async () => {
        let payload = {
            Date: new Intl.DateTimeFormat("ko-KR").format(value).replaceAll(". ", "-").replaceAll(".", "")
        };
        let apiRes = await apiCall.Getguide(payload);
        if (apiRes?.status >= 200 || apiRes?.status <= 399) {
            setGuide_details(apiRes?.data?.scheduleDateGuide);
            // }
        }
    };

    // craete tour api
    const handleSubmit = async () => {
        if (!tourDetails.date) {
            toast.error("Please select a date for the tour");
            return;
        }

        const date = new Date(tourDetails.date);
        // const formattedDate = date.toLocaleDateString('en-GB', { year: 'numeric', day: '2-digit', month: '2-digit'});
        // console.log("fom",formattedDate);
        const formattedDate = moment(date).format('YYYY-MM-DD');

            // console.log(formattedDate);

        let payload = {
            name: tourDetails.name,
            type: tourDetails.type,
            language: tourDetails.language,
            time_to: `${tourDetails.startTimeHour}:${tourDetails.startTimeMinutes}`,
            time_from: `${tourDetails.endTimeHour}:${tourDetails.endTimeMinutes}`,
            time_status: `${tourDetails.time_status}`,
            date: formattedDate
        };
        if (tourDetails.name) {
            let apiRes = await apiCall.PostGuideDetails(payload);
            if (apiRes?.status >= 200 || apiRes?.status <= 399) {
                GetTour();
                toast.success("Tour has been added successfully");
            };
            setIsNameValid(false)
            setTourDetails({ ...tourDetails, name: "" })
        }
        else if (!tourDetails.name || !tourDetails) {
            setIsNameValid(true)
            toast.error("Please Enter tour name")
        };

    };

    let GetLanguage = async () => {
        let apiRes = await apiCall.GetLanguages();
        if (apiRes?.status >= 200 || apiRes?.status <= 399) {
            setLanguages(apiRes?.data);

        }
    };

    // get tour api
    let GetTour = async () => {
        let apiRes1 = await apiCall.GetTourDetails();
        if (apiRes1?.status >= 200 || apiRes1?.status <= 399) {
            setlist_Tour(apiRes1?.data);
        };
    };

    const SelectedTour = async (id) => {
        let payload = {
            id: id,
            date: value
        }
        let apiRes = await apiCall.GetSelectedTour(payload);
        if (apiRes?.status >= 200 || apiRes?.status <= 399) {
            setGuide_details(apiRes?.data?.guide);
            GuideAvailability();
        };
        guide_listing?.current?.scrollIntoView({ behavior: "smooth" })
    };

    const SelectedGuide = async (id) => {
        let payload = {
            TourId: tour_id,
            GuideId: id,
            Date: [new Intl.DateTimeFormat("ko-KR").format(value).replaceAll(". ", "-").replaceAll(".", "")]
        }
        let apiRes = await apiCall.GetSelectedGuide(payload);
        if (apiRes?.status >= 200 || apiRes?.status <= 399) {
            toast.success("Guide assigned successfully");

        }
        else {
            toast.error("Please Select tour");
        }
        guide_listing?.current?.scrollIntoView({ behavior: "smooth" })
    };

    // handle main Api
    const handleMainApi = async () => {
        let payload = {
            id: tour_id,
            date: moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS\\Z'),
            guide_id: submitData.guide_listing_id
        };
        let apiRes = await apiCall.mainApi(payload);
        if (apiRes?.status >= 200 || apiRes?.status <= 399) {
            toast.success(apiRes.message);
            GetTour();
            setTour_id("");
            setSubmitData({ ...submitData, guide_listing_id: "" });
            onChange(new Date());
        };
    };

    // handle send all data validation
    const handleSendAllData = (e) => {
        e.preventDefault();
        if (!tour_id) {
            toast.error("Please choose a tour from the available listings.");
        } else if (!submitData.guide_listing_id) {
            toast.error("Please select a guide from the available listings.");
        } else {
            handleMainApi();
        };
    };

    // show message when user select tour
    const getHandleGuideName = (name) => {
        setTourListScroll(name);
        if (name) {
            toast.info(`Tour already assigned to ${`${name?.split("")[0]?.toUpperCase()}${name?.split("")?.slice(1)?.join("")}`}.`);
        };
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDateSelected, setIsDateSelected] = useState(true);
    // var today = new Date();
    const handleDateChange = (date) => {

        console.log("e", date);
        setSelectedDate(date);
    };

    useEffect(() => {
        GuideAvailability();
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        GetLanguage();
        GetTour();
    }, []);


    return (
        <div>
            <header className="">
                <div className="logo_bg m-auto position-absolute">
                    <img className="w-100 p-4" src="./assets/images/svg/showlogo.svg" alt="showlogo" />
                </div>
            </header>
            <main>
                <section className="hero_sec position-relative">
                    <div className="container-fluid hero_inner_sec">
                        <div className="row">
                            <div className="col-12">
                                <img className="w-100 " src="./assets/images/png/manymanimg.png" alt="manymenimg" />

                                <div className="listing_bg py-2 py-md-5 px-3  ">
                                    <div className="row">
                                    <span className="view-cal-btn d-flex fw_bold my-2" onClick={()=>navigate("/calendar")}><FaRegCalendarAlt/></span>

                                        <div className="col-lg-6">
                                            <h3 className="color_black fw_bold fs_lg fs_sm_md">Add Tour</h3>
                                            <div className="field_bg px-3 py-2">
                                                <label for="name" className={`fw_bold color_black fs_2xsm ${isNameValid ? `sap-error-val` : ``}`}>Tour Name</label>
                                                <input type="text" placeholder="Williamsmith" required onChange={(e) => setTourDetails({ ...tourDetails, name: e.target.value })} value={tourDetails.name} className={`w-100 py-2 tour_nme ${isNameValid ? `sap-error-val` : ``}`} />
                                                <label for="" className="mt-4 fw_bold fs_2xsm  color_black">Tour Type</label>
                                                <select name="" onChange={(e) => setTourDetails({ ...tourDetails, type: e.target.value })} required id="" className="w-100  drop    py-1">
                                                    <option value="Arena">Arena</option>
                                                    <option value="Regular">Regular</option>
                                                    <option value="UGOR">UGOR</option>
                                                    <option value="UGVI">UGVI</option>
                                                </select>
                                                <label for="" className="mt-4 fw_bold fs_2xsm  color_black">Choose Langauge</label>
                                                <select onChange={(e) => setTourDetails({ ...tourDetails, language: e.target.value })} name="" required id="" className="w-100  drop    py-1">
                                                    {
                                                        languages && languages?.map((ele) => {
                                                            return (<option value={ele.name}>{ele.name}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='left-side-time'>
                                                    <label for="" className="mt-4 fw_bold fs_2xsm  color_black">Date</label>
                                                    <br />
                                                    <DatePicker
                                                        selected={tourDetails.date}
                                                        // onChange={handleDateChange}
                                                        onChange= {(e) => setTourDetails({ ...tourDetails, date: e })}
                                                        dateFormat="yyyy-MM-dd"
                                                        minDate={new Date()} 
                                                        // isClearable
                                                        placeholderText="Select a date"
                                                        // readOnly={true} 
                                                        />
                                                        
                                                    {!isDateSelected && (
                                                        <div style={{ color: 'red' }}>Please select a date</div>
                                                    )}

                                                </div>
                                                <label for="" className="mt-4 fw_bold fs_2xsm  color_black">Time</label>
                                                <br />


                                                <div className='time-start-end'>
                                                    <div className='left-side-time'>
                                                        <label for="" className="my-2 fw_bold fs_2xsm  color_black">To</label>
                                                        <div className='time-to'>
                                                            <div className='to-start-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, startTimeHour: e.target.value })}>
                                                                    {timeStart.map((data) => {
                                                                        return (
                                                                            <option value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className='to-end-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, startTimeMinutes: e.target.value })}>
                                                                    {timeEnd.map((data) => {
                                                                        return (
                                                                            <option value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='right-side-time'>
                                                        <label for="" className="my-2 fw_bold fs_2xsm  color_black">From</label>
                                                        <div className='time-from'>
                                                            <div className='to-end-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, endTimeHour: e.target.value })}>
                                                                    {timeStart.map((data) => {
                                                                        return (
                                                                            <option
                                                                                value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className='to-end-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, endTimeMinutes: e.target.value })}>
                                                                    {timeEnd.map((data) => {
                                                                        return (
                                                                            <option value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='right-side-time'>
                                                        <label for="" className="my-2 fw_bold fs_2xsm  color_black">Time-Status</label>
                                                        <div className='time-from'>
                                                            <div className='to-end-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, time_status: e.target.value })}>
                                                                    {time_status.map((data) => {
                                                                        return (
                                                                            <option
                                                                                value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            {/* <div className='to-end-time'>
                                                                <select onChange={(e) => setTourDetails({ ...tourDetails, endTimeMinutes: e.target.value })}>
                                                                    {timeEnd.map((data) => {
                                                                        return (
                                                                            <option value={data}>{data}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div onClick={(e) => handleSubmit(e)} className="create_btn text-center mb-3">
                                                    <span className="color_white">CREATE TOUR</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <h3 className="color_black fw_bold fs_lg fs_sm_md mt-3 mt-lg-0">Listing of Tour</h3>
                                            <div className={`over_scrol`}>
                                                {
                                                    list_Tour?.length > 0 ? list_Tour?.map((ele, index) => {
                                                        const { _id, name, type, language, guideName } = ele;
                                                        return (
                                                            <div key={index} onClick={() => { SelectedTour(_id); setTour_id(_id); getHandleGuideName(guideName) }}
                                                                className={`listing_cont_bg p-3 ${tour_id === _id ? `active` : ``}`}>
                                                                <h3 className="fw_md fs_xsm color_secondary">Tour Name : {name}</h3>
                                                                <h3 className="fw_md fs_xsm color_secondary">Tour Type : {type}</h3>
                                                                <h3 className="fw_md fs_xsm color_secondary">Tour Language : {language} </h3>
                                                                <h3 className="fw_md fs_xsm text-capitalize color_secondary">Guide Name : {guideName ? guideName : "N/A"} </h3>
                                                            </div>
                                                        )
                                                    }) : <p className='tour-message'>Visitors cannot currently book tours !</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-lg-8 m-auto">

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Home
import axios from "axios";
async function Getguide(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}admin/availability-filter-web`,{
        date: payload.Date,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }
async function PostGuideDetails(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}admin/add-tours`,{
        name: payload.name,
        type: payload.type,
        language: payload.language,
        time_to:payload.time_to,
        time_from:payload.time_from,
        time_status:payload.time_status,
        date:payload.date
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }
async function GetSelectedTour(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}guide/guide-list-for-web`,{
        id: payload.id,
        date:payload.date
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  };

async function GetSelectedGuide(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}guide/guide-list-web-post`,{
        guide_id: payload.GuideId,
        id: payload.TourId,
        date:payload.Date
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }
  
async function GetTourDetails() {
    let getResponse;
    await axios.get(`${process.env.REACT_APP_BASE_URL}admin/get-tours`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
        // console.log("getResponse",getResponse)
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }
async function GetLanguages() {
    let getResponse;
    await axios.get(`${process.env.REACT_APP_BASE_URL}guide/choose-language`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
        // console.log("getResponse",getResponse)
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  };

  // main api
  async function mainApi(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}guide/guide-list-web-post`,{
        id: payload.id,
        date: payload.date,
        guide_id: payload.guide_id
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  };

  async function GetScheduleWeb() {
    let getResponse;
    await axios.get(`${process.env.REACT_APP_BASE_URL}guide/get-schedule-web`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }

  async function GetGuideWeb() {
    let getResponse;
    await axios.get(`${process.env.REACT_APP_BASE_URL}admin/guides-for-tours`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
        // console.log("getResponse-----",getResponse);
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  }

  async function assignGuide(payload) {
    let getResponse;
    await axios.post(`${process.env.REACT_APP_BASE_URL}admin/assigned-guide`,{
        tour_id: payload.tour_id,
        guide_id: payload.guide_id
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
  )
      .then(function (response) {
        getResponse = response?.data
      })
      .catch(function (error) {
        // console.log("err",error);
      });
    return getResponse;
  };


  // eslint-disable-next-line
  export default {
    Getguide,
    PostGuideDetails,
    GetTourDetails,
    GetLanguages,
    GetSelectedTour,
    GetSelectedGuide,
    mainApi,
    GetScheduleWeb,
    GetGuideWeb,
    assignGuide
  };
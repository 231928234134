import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Slide, ToastContainer, Zoom } from 'react-toastify';

import Home from './Components/Home';

import './App.scss';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventCalendars from './Components/calendar/EventCalendars';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="calendar" element={<EventCalendars />} />
        </Routes>
      </Router>
      <ToastContainer theme="dark" position="top-right"  transition={Zoom} autoClose={3000}/>
    </>
  );
}

export default App;
